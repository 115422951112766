import React, { useContext } from "react";
import Moment from "moment";
import { Autocomplete, Badge, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PostulacionContext } from "../index";
import FileInput from "../../../components/fileInput";
import { MontoToMoneda } from "../../../services/formatUtils";

export default function Personal(props) {
	const {
		generos,
		formik,
		cant_errores,
	} = useContext(PostulacionContext);

	/**
	 * Método encargado de formatear la pretención de renta.
	 * @param {*} e Evento al ingresar texto.
	 */
	const handleChangePretencionRenta = (e) => {
		let nombreCampo = e.target.name;
		let monto = MontoToMoneda(e.target.value);
		//Si el monto es menor al límite.
		if (monto.length < 12) {
			formik.setFieldValue(nombreCampo, monto);
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Badge badgeContent={cant_errores} color="primary">
					<Typography variant="h6" color="darkblue" style={{ marginLeft: 10, marginRight: 10 }}>Información necesaria para postular</Typography>
				</Badge>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="run"
					label="RUT"
					value={formik.values.run}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.run}
					error={Boolean(formik.errors.run)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="nombre"
					label="Nombre"
					value={formik.values.nombre}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.nombre}
					error={Boolean(formik.errors.nombre)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="apellido_paterno"
					label="Primer Apellido"
					value={formik.values.apellido_paterno}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.apellido_paterno}
					error={Boolean(formik.errors.apellido_paterno)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="apellido_materno"
					label="Segundo Apellido"
					value={formik.values.apellido_materno}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					// helperText={formik.errors.apellido_materno}
					error={Boolean(formik.errors.apellido_materno)}
				/>
			</Grid>
			<Grid item xs={3}>
				<DatePicker
					label="Fecha de Nacimiento"
					value={formik.values.fecha_nacimiento}
					onChange={date => formik.setFieldValue("fecha_nacimiento", date)}
					views={["year", "month", "day"]}
					openTo="year"
					minDate={Moment().add(-80, "years").startOf("year")}
					maxDate={Moment().add(-18, "years").endOf("year")}
					renderInput={(params) => (
						<TextField
							size="small"
							fullWidth
							style={{ backgroundColor: "white" }}
							// helperText={formik.errors.fecha_nacimiento}
							error={Boolean(formik.errors.fecha_nacimiento)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="contacto.email"
					label="Email"
					value={formik.values.contacto.email}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					type="email"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.contacto && formik.errors.contacto.email}
					error={Boolean(formik.errors.contacto && formik.errors.contacto.email)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="contacto.telefono"
					label="Teléfono"
					value={formik.values.contacto.telefono}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.contacto && formik.errors.contacto.telefono}
					error={Boolean(formik.errors.contacto && formik.errors.contacto.telefono)}
				/>
			</Grid>
			<Grid item xs={3}>
				<Autocomplete
					name="genero"
					options={generos || []}
					value={formik.values.genero}
					onChange={(event, value) => formik.setFieldValue("genero", value)}
					getOptionLabel={(genero) => genero}
					size="small"
					fullWidth
					noOptionsText="Sin opciones"
					renderInput={(params) => (
						<TextField
							label="Género"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							required
							// helperText={formik.errors.genero}
							error={Boolean(formik.errors.genero)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					name="detalle.pretencion_renta_formato"
					label="Pretensiones de renta líquida (pesos)"
					value={formik.values.detalle.pretencion_renta_formato}
					onChange={handleChangePretencionRenta}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					required
					// helperText={formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato}
					error={Boolean(formik.errors.detalle && formik.errors.detalle.pretencion_renta_formato)}
				/>
			</Grid>
			<Grid item xs={9}>
				<FileInput
					inputName="file"
					label="CV en formato PDF *"
					handleOnChange={(e) => formik.setFieldValue("file", e.target.files[0])}
					meta={{ touched: formik.touched.file, error: formik.errors.file }}
					accept="application/pdf"
					buttonColor="info"
					buttonVariant="contained"
					value={formik.values.file}
					textVariant="outlined"
					size="small"
					style={{ backgroundColor: "white" }}
				/>
			</Grid>
		</Grid>
	);
}