import React, { useContext } from "react";
import { Autocomplete, Button, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import { PostulacionContext } from "./index";
import Personal from "./secciones/personal";
import Ubicacion from "./secciones/ubicacion";
import Adicional from "./secciones/personal_adicional";
import Estudios from "./secciones/estudios";
import Experiencias from "./secciones/experiencias";
import Adjuntos from "./secciones/adjuntos";
import ZonasTrabajo from "../../services/zonas_trabajo.json";

export default function Page(props) {

	const { porcentaje, formik } = useContext(PostulacionContext);

	return (
		<Grid container spacing={2} style={{ padding: 50, paddingTop: 100 }}>
			{/* COMENTARIO DE AGRADECIMIENTO */}
			<Grid item xs={12}>
				<Typography>Hola! Agradecemos tu interés en formar parte de CyD Ingeniería. Completa el formulario según lo indicado.</Typography>
			</Grid>

			{/* PORCENTAJE DE CARGA DE INFORMACIÓN */}
			<Grid item xs={9}>
				<Typography variant="h6" color="darkblue">Trabaja con Nosotros</Typography>
				<LinearProgress value={porcentaje} variant="determinate" color="info" />
			</Grid>

			{/* BOTÓN PARA ENVIAR DATOS */}
			<Grid item xs={3}>
				<Button onClick={formik.handleSubmit} variant="contained" size="large" color="inherit" fullWidth>Enviar</Button>
			</Grid>

			{/* FORMULARIO INFORMACIÓN PERSONAL */}
			<Grid item xs={12}>
				<Personal />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL */}
			<Grid item xs={12}>
				<Typography variant="h6" color="darkblue" style={{ marginTop: 30 }}>Información complementaria (opcional)</Typography>
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | UBICACIÓN */}
			<Grid item xs={6}>
				<Ubicacion />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | ADICIONAL */}
			<Grid item xs={6}>
				<Adicional />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | ESTUDIOS */}
			<Grid item xs={6}>
				<Estudios />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | EXPERIENCIAS */}
			<Grid item xs={6}>
				<Experiencias />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | ADJUNTOS */}
			<Grid item xs={6}>
				<Adjuntos />
			</Grid>

			{/* FORMULARIO INFORMACIÓN OPCIONAL | ZONAS DE TRABAJO */}
			<Grid item xs={6} container>
				<Grid item xs={12} style={{ marginTop: 50 }}>
					<Autocomplete
						name="detalle.zonas_trabajo_object"
						options={ZonasTrabajo}
						getOptionLabel={(option) => option}
						value={formik.values.detalle.zonas_trabajo_object}
						onChange={(event, value) => formik.setFieldValue("detalle.zonas_trabajo_object", value)}
						fullWidth
						multiple
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="Estaría dispuesto a trabajar en: (zona norte, sur, centro, fuera de Chile)"
								variant="outlined"
								style={{ backgroundColor: "white" }}
								helperText={formik.errors.detalle && formik.errors.detalle.zonas_trabajo_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.zonas_trabajo_object)}
								{...params}
							/>
						)}
					/>
				</Grid>

				{/* FORMULARIO INFORMACIÓN OPCIONAL | SISTEMA DE TURNO */}
				<Grid item xs={12}>
					<Autocomplete
						name="detalle.sistema_turno_object"
						options={[{ label: "Si", valor: true }, { label: "No", valor: false }]}
						getOptionLabel={(option) => option.label}
						value={formik.values.detalle.sistema_turno_object}
						onChange={(event, value) => formik.setFieldValue("detalle.sistema_turno_object", value)}
						fullWidth
						noOptionsText="Sin opciones"
						renderInput={(params) => (
							<TextField
								label="¿Estaría dispuesto a trabajar en sistema de turnos? (sí o no)"
								variant="outlined"
								style={{ backgroundColor: "white" }}
								helperText={formik.errors.detalle && formik.errors.detalle.sistema_turno_object}
								error={Boolean(formik.errors.detalle && formik.errors.detalle.sistema_turno_object)}
								{...params}
							/>
						)}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
}