import React, { Fragment, useContext, useState } from "react";
import { captureException } from "@sentry/react";
import { useSnackbar } from "notistack";
import { IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import MaterialTable from "../../../components/materialTable";
import { PostulacionContext } from "../index";
import AdjuntoDialog from "./adjunto_form";

export default function Adjuntos(props) {
	const [IsOpen, SetIsOpen] = useState(false);

	const { formik } = useContext(PostulacionContext);
	const notistack = useSnackbar();

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Nombre archivo",
			field: "file.name",
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarAdjunto(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} adjunto Datos del adjunto.
	 */
	const handleEliminarAdjunto = async (adjunto) => {
		try {
			let adjuntos = Array.from(formik.values.adjuntos);
			//Se elimina el adjunto.
			adjuntos.splice(adjunto.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("adjuntos", adjuntos);

			notistack.enqueueSnackbar("Adjunto del postulante eliminado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			captureException(error);
			notistack.enqueueSnackbar("Error al intentar eliminar el adjunto del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			<Typography variant="h6" style={{ marginLeft: 10 }}>Adjunta aquí tus documentos de respaldo</Typography>

			{/* LISTADO DE ADJUNTOS */}
			{!IsOpen && (
				<MaterialTable
					title="Adjuntos"
					is_loading={false}
					data={formik.values.adjuntos}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			)}

			{/* DIALOG ADJUNTO */}
			<AdjuntoDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}