import React, { Fragment, useContext, useState } from "react";
import { captureException } from "@sentry/react";
import { useSnackbar } from "notistack";
import { IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import MaterialTable from "../../../components/materialTable";
import { PostulacionContext } from "../index";
import ExperienciaDialog from "./experiencia_form";

export default function Experiencias(props) {
	const [IsOpen, SetIsOpen] = useState(false);

	const { formik } = useContext(PostulacionContext);
	const notistack = useSnackbar();

	let columns = [
		{
			title: "Empresa",
			field: "empresa",
		}, {
			title: "Cargo",
			field: "cargo",
		}
	];

	let actions = [
		{
			tooltip: "Eliminar",
			icon: "delete",
			onClick: (event, row) => handleEliminarExperiencia(row),
		}, {
			tooltip: "Agregar",
			icon: "add",
			onClick: () => SetIsOpen(true),
			isFreeAction: true,
		}
	];

	/**
	 * Método encargado de eliminar un registro agregado.
	 * @param {*} experiencia Datos de la experiencia.
	 */
	const handleEliminarExperiencia = async (experiencia) => {
		try {
			let experiencias = Array.from(formik.values.experiencias);
			//Se elimina la experiencia.
			experiencias.splice(experiencia.tableData.id, 1);

			//Se actualizan los datos del postulante.
			formik.setFieldValue("experiencias", experiencias);

			notistack.enqueueSnackbar("Experiencia del postulante eliminada exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			captureException(error);
			notistack.enqueueSnackbar("Error al intentar eliminar la experiencia del postulante.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
			});
		}
	}

	return (
		<Fragment>
			<Typography variant="h6" style={{ marginLeft: 10 }}>Detalla aquí tu experiencia</Typography>

			{/* LISTADO DE EXPERIENCIAS */}
			{!IsOpen && (
				<MaterialTable
					title="Experiencias"
					is_loading={false}
					data={formik.values.experiencias}
					columns={columns}
					actions={actions}
					filtering={false}
					grouping={false}
					searching={false}
					columnsButton={false}
				/>
			)}

			{/* DIALOG EXPERIENCIA */}
			<ExperienciaDialog
				is_open={IsOpen}
				set_is_open={SetIsOpen}
			/>
		</Fragment>
	);
}