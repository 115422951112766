import Moment from "moment";

/**
 * Método encargado de formatear el nombre y apellidos de un postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre completo formateado.
 */
export function FormatearNombre(persona) {
	if (!persona) {
		return "";
	}
	let nombre = `${String(persona.nombre).charAt(0).toUpperCase()}${String(persona.nombre).split(" ")[0].slice(1)}`;
	let apaterno = `${String(persona.apellido_paterno).charAt(0).toUpperCase()}${String(persona.apellido_paterno).slice(1)}`;
	let amaterno = `${String(persona.apellido_materno).charAt(0).toUpperCase()}${String(persona.apellido_materno).slice(1)}`;
	return `${nombre} ${apaterno} ${amaterno}`;
}

/**
 * Método encargado de formatear una fecha.
 * @param {*} fecha Fecha que será formateada.
 * @param {*} formato Formato opcional. Valor predeterminado "DD/MM/YYYY".
 * @returns Fecha formateada.
 */
export function FormatearFecha(fecha, formato = "DD/MM/YYYY") {
	if (!fecha) {
		return FECHA_FORMATO_VACIO;
	}
	let fechaFormateada = Moment(fecha).format(formato);
	return fechaFormateada;
}

/**
 * Método encargado de obtener el rango desde hasta de un dato.
 * @param {*} data Dato con fecha de inicio y término.
 * @returns Rango de fecha desde.
 */
export function RangoFechas(data) {
	let fechaInicio = Moment(data.fecha_inicio);
	let fechaTermino = Moment(data.fecha_termino);
	if (fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")} Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (!fechaInicio.isValid() && fechaTermino.isValid()) {
		return `Hasta ${fechaTermino.format("DD/MM/YYYY")}`;
	} else if (fechaInicio.isValid() && !fechaTermino.isValid()) {
		return `Desde ${fechaInicio.format("DD/MM/YYYY")}`;
	} else {
		return `Desde ${FECHA_FORMATO_VACIO} Hasta ${FECHA_FORMATO_VACIO}`;
	}
}

/**
 * Método encargado de generar un texto con nombre completo del usuario.
 * @param {*} postulante Datos del postulante.
 * @returns Nombre completo.
 */
export function NombreCompleto(postulante) {
	let nombrePostulante = "";
	if (postulante && postulante.nombre) {
		nombrePostulante += postulante.nombre;
	}
	if (postulante && postulante.apellido_paterno) {
		nombrePostulante += ` ${postulante.apellido_paterno}`;
	}
	if (postulante && postulante.apellido_materno) {
		nombrePostulante += ` ${postulante.apellido_materno}`;
	}
	return ToTitleCase(nombrePostulante);
}

/**
 * Método encargado de aplicar TitleCase a un texto.
 * @param {*} text Texto que será cambiado.
 * @returns Texto con formato TitleCase.
 */
export function ToTitleCase(text) {
	if (!text) {
		return null;
	}
	let texto = String(text).trim().replace(/  +/g, ' ');
	let result = texto.split(" ").map(m => `${m[0].toUpperCase()}${m.slice(1)}`).join(" ");
	return result;
}

/**
 * Método encargado de calcular la cantidad de años desde una fecha.
 * @param {*} fecha Fecha desde cuando realizar el cálculo.
 * @param {*} decimales Cantidad de decimales opcional. Valor predeterminado es 0.
 * @returns Cantidad de años.
 */
export function AñosDesde(fecha, decimales = 0) {
	let months = Moment().diff(Moment(fecha), "months") / 12;
	let years = Number(months).toFixed(decimales);
	return years > 0 ? years : "";
}

/**
 * Método encargado de retornar los años y meses desde una fecha.
 * @param {*} fecha Fecha desde cuando se hará el cálculo.
 * @param {*} formatoYears Formato con el que se mostrarán los años.
 * @param {*} formatoMonths Formato con el que se mostrarán los meses.
 * @link https://stackoverflow.com/a/4512317
 * @returns Fecha en formato X años Y meses.
 */
export function EdadFormato(fecha, formatoYears = "y", formatoMonths = "m") {
	//Si la fecha no es válida.
	if (!fecha) {
		return "";
	}

	let yearsString = AñosDesde(fecha, 2);
	let years = Number(yearsString);
	let decimal = years % 1;
	let months = (decimal * 12).toFixed(0);

	if (years > 0 && months > 0) {
		return `${years.toFixed(0)}${formatoYears} ${months}${formatoMonths}`;
	} else if (years > 0 && months === "0") {
		return `${years.toFixed(0)}${formatoYears}`;
	} else {
		return `${months}${formatoMonths}`;
	}
}

/**
 * Método encargado de formatear el tamaño de un archivo.
 * @param {*} bytes Tamaño del archivo en bytes.
 * @returns Tamaño formateado.
 */
export function BytesToSize(bytes) {
	const units = ["byte", "kilobyte", "megabyte", "terabyte", "petabyte"];
	const unit = Math.floor(Math.log(bytes) / Math.log(1024));
	return new Intl.NumberFormat("es", { style: "unit", unit: units[unit], maximumFractionDigits: 1 }).format(bytes / 1024 ** unit);
}

/**
 * Método encargado de formatear el nombre de un CV.
 * @param {*} name Nombre del CV.
 * @returns Nombre del CV formateado.
 */
export function CurriculumNameFormat(name) {
	let nombre = String(name).split(/_/)
		.slice(0, 2)
		.join("")
		.split(/(?=[A-Z])/)
		.join(" ");
	return nombre;
}

/**
 * Método encargado de formatear un monto a formato moneda CLP.
 * @param {*} monto Monto sin formato dinero.
 * @returns Monto con formato dinero CLP.
 */
export function MontoToMoneda(monto) {
	let monedaNumber = String(monto).replace(/\D/g, "");
	let monedaFormato = Number(monedaNumber).toLocaleString("es-CL", {
		style: "currency",
		currency: "CLP",
	});
	return monedaFormato;
};

/**
 * Método encargado de obtener el monto de un monto de dinero.
 * @param {*} moneda Monto con formato dinero.
 * @returns Monto sin formato dinero CLP.
 */
export function MonedaToMonto(moneda) {
	let monto = String(moneda).replace(/\D/g, "");
	let montoNumber = Number(monto);
	return montoNumber;
};

/**
 * Método encargado de obtener el detalle del porcentaje de completitud de información.
 * @param {*} postulante Datos del postulante.
 * @param {*} cant_estudios Cantidad de estudios del postulante.
 * @param {*} cant_experiencias Cantidad de experiencias del postulante.
 * @param {*} flagNumber FLAG para retornar el porcentaje en formato Number. Valor FALSE por default.
 * @returns Colección con el detalle de los porcentajes.
 */
export function PorcentajeCompletitud(postulante, cant_estudios, cant_experiencias, flagNumber = false) {
	let porcentajePersonal = INFORMACION_PERSONAL_TOTAL / INFORMACION_PERSONAL;
	let porcentajePersonalTotal = 0;
	porcentajePersonalTotal += postulante.run ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.nombre ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.apellido_paterno ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.apellido_materno ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.fecha_nacimiento ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.genero ? porcentajePersonal : 0;
	porcentajePersonalTotal += postulante.nacionalidad ? porcentajePersonal : 0;

	let porcentajeContacto = INFORMACION_CONTACTO_TOTAL / INFORMACION_CONTACTO;
	let porcentajeContactoTotal = 0;
	if (postulante.contacto) {
		porcentajeContactoTotal += postulante.contacto.telefono ? porcentajeContacto : 0;
		porcentajeContactoTotal += postulante.contacto.email ? porcentajeContacto : 0;
	}

	let porcentajeUbicacion = INFORMACION_UBICACION_TOTAL / INFORMACION_UBICACION;
	let porcentajeUbicacionTotal = 0;
	if (postulante.ubicacion) {
		porcentajeUbicacionTotal += postulante.ubicacion.direccion ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.ciudad ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.comuna ? porcentajeUbicacion : 0;
		porcentajeUbicacionTotal += postulante.ubicacion.region ? porcentajeUbicacion : 0;
	}

	let porcentajeAdicional = INFORMACION_ADICIONAL_TOTAL / INFORMACION_ADICIONAL;
	let porcentajeAdicionalTotal = 0;
	if (postulante.detalle) {
		porcentajeAdicionalTotal += postulante.detalle.nivel_ingles ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.licencias_conducir.length > 0 ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.is_discapacitado ? porcentajeAdicional : 0;
		porcentajeAdicionalTotal += postulante.detalle.relacion_cyd ? porcentajeAdicional : 0;
	}

	let porcentajeEstudiosTotal = Boolean(cant_estudios <= 3) ? (ESTUDIOS * cant_estudios) : 15;
	let porcentajeExperienciasTotal = Boolean(cant_experiencias <= 3) ? (EXPERIENCIAS * cant_experiencias) : 30;

	let porcentajeTotal = porcentajePersonalTotal + porcentajeContactoTotal + porcentajeUbicacionTotal + porcentajeAdicionalTotal + porcentajeExperienciasTotal;

	let porcentajes = [
		`Personal: ${porcentajePersonalTotal.toFixed(1)}%`,
		`Contacto: ${porcentajeContactoTotal.toFixed(1)}%`,
		`Ubicación: ${porcentajeUbicacionTotal.toFixed(1)}%`,
		`Adicional: ${porcentajeAdicionalTotal.toFixed(1)}%`,
		`Estudios: ${porcentajeEstudiosTotal.toFixed(1)}%`,
		`Experiencias: ${porcentajeExperienciasTotal.toFixed(1)}%`,
		`Total: ${porcentajeTotal.toFixed(1)}%`,
	];

	return flagNumber ? porcentajeTotal : porcentajes;
}

/*********************************:*************************************************
 * 																CONSTANTES
 **********************************************************************************/
export const FECHA_FORMATO_VACIO = "--/--/--";
const INFORMACION_PERSONAL = 7;
const INFORMACION_PERSONAL_TOTAL = 20;
const INFORMACION_CONTACTO = 2;
const INFORMACION_CONTACTO_TOTAL = 20;
const INFORMACION_UBICACION = 4;
const INFORMACION_UBICACION_TOTAL = 10;
const INFORMACION_ADICIONAL = 4;
const INFORMACION_ADICIONAL_TOTAL = 5;
const ESTUDIOS = 5;
const EXPERIENCIAS = 10;