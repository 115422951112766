import React, { useContext } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { PostulacionContext } from "../index";

export default function Ubicacion(props) {
	const {
		regiones_comunas,
		formik,
	} = useContext(PostulacionContext);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6" style={{ marginLeft: 10 }}>Cuéntanos dónde vives</Typography>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="ubicacion.region_object"
					options={regiones_comunas ? regiones_comunas : []}
					getOptionLabel={(region) => region.nombre}
					value={formik.values.ubicacion.region_object}
					onChange={(event, value) => {
						formik.setFieldValue("ubicacion.comuna", null);
						formik.setFieldValue("ubicacion.region_object", value);
					}}
					size="small"
					fullWidth
					renderInput={(params) => (
						<TextField
							label="Región"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.ubicacion && formik.errors.ubicacion.region_object}
							error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.region_object)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="ubicacion.comuna"
					options={regiones_comunas && formik.values.ubicacion.region_object ? regiones_comunas.find(rc => rc.nombre === formik.values.ubicacion.region_object.nombre)?.comunas : []}
					getOptionLabel={(comuna) => comuna}
					value={formik.values.ubicacion.comuna}
					onChange={(eveny, value) => formik.setFieldValue("ubicacion.comuna", value)}
					disabled={!formik.values.ubicacion.region_object}
					size="small"
					fullWidth
					renderInput={(params) => (
						<TextField
							label="Comuna"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.ubicacion && formik.errors.ubicacion.comuna}
							error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.comuna)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name="ubicacion.ciudad"
					label="Ciudad"
					value={formik.values.ubicacion.ciudad}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					helperText={formik.errors.ubicacion && formik.errors.ubicacion.ciudad}
					error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.ciudad)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					name="ubicacion.direccion"
					label="Dirección"
					value={formik.values.ubicacion.direccion}
					onChange={formik.handleChange}
					variant="outlined"
					size="small"
					fullWidth
					style={{ backgroundColor: "white" }}
					helperText={formik.errors.ubicacion && formik.errors.ubicacion.direccion}
					error={Boolean(formik.errors.ubicacion && formik.errors.ubicacion.direccion)}
				/>
			</Grid>
		</Grid>
	);
}