import React, { createContext, useEffect } from "react";
import { captureException } from "@sentry/react";
import useSWR from "swr";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import Moment from "moment";
import Page from "./page";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import * as PostulanteRequest from "../../services/request/postulantes";
import * as CurriculumRequests from "../../services/request/curriculums";
import * as SeleccionableRequests from "../../services/request/seleccionables";
import * as EmpresaRequests from "../../services/request/empresas";
import { uploadFileToCloudStorage } from "../../services/fileUpload";
import { SendHTML } from "../../services/email";
import { PostulacionExitosa } from "../../services/email_template";
import { MonedaToMonto, PorcentajeCompletitud, ToTitleCase } from "../../services/formatUtils";
import RegionesComunas from "../../services/regiones_comunas.json";
import Generos from "../../services/generos.json";

export const PostulacionContext = createContext({
	regiones_comunas: [],
	generos: [],
	discapacidades: { data: [], isValidating: Boolean() },
	licencias_conducir: { data: [], isValidating: Boolean() },
	niveles_ingles: { data: [], isValidating: Boolean() },
	niveles_estudio: { data: [], isValidating: Boolean() },
	instituciones: { data: [], isValidating: Boolean() },
	titulos: { data: [], isValidating: Boolean() },
	relaciones_cyd: { data: [], isValidating: Boolean() },
	empresas: { data: [], isValidating: Boolean() },
	porcentaje: 0,
	cant_errores: 7,
	formik: null,
});

export default function Index(props) {
	const notistack = useSnackbar();

	const DiscapacidadesSWR = useSWR("discapacidades_detalles", SeleccionableRequests.ObtenerDiscapacidades, { revalidateOnFocus: false });
	const LicenciasConducirSWR = useSWR("licencias_conducir_detalles", SeleccionableRequests.ObtenerLicenciasConducir, { revalidateOnFocus: false });
	const NivelesInglesSWR = useSWR("niveles_ingles_detalles", SeleccionableRequests.ObtenerNivelesIngles, { revalidateOnFocus: false });
	const InstitucionesSWR = useSWR("instituciones_detalles", SeleccionableRequests.ObtenerInstituciones, { revalidateOnFocus: false });
	const TitulosSWR = useSWR("titulos_detalles", SeleccionableRequests.ObtenerTitulos, { revalidateOnFocus: false });
	const RelacionesSWR = useSWR("relaciones_cyd_detalles", SeleccionableRequests.ObtenerRelacionesCyD, { revalidateOnFocus: false });
	const NivelesEstudioSWR = useSWR("niveles_estudio_detalles", SeleccionableRequests.ObtenerNivelesEstudio, { revalidateOnFocus: false });
	const EmpresasSWR = useSWR("empresas_detalles", EmpresaRequests.Obtener, { revalidateOnFocus: false });

	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				//RUN del postulante con dígito verificado en minuscula.
				let postulanteRUN = values.run.toLowerCase();
				//Se verifica si el RUN ya está registrado.
				let check = await PostulanteRequest.VerificarRUN(postulanteRUN);
				if (check && false) {
					//Si el RUN ya está registrado.
					notistack.enqueueSnackbar("Ya existe una postulación con sus datos.", {
						variant: "info",
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});
				} else {
					//Si el RUN no está registrado.
					notistack.enqueueSnackbar("Subiendo su postulación...", {
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
					});

					//Se guardan en lower case.
					values.run = postulanteRUN;
					values.nombre = values.nombre.toLowerCase();
					values.apellido_paterno = values.apellido_paterno.toLowerCase();
					values.apellido_materno = values.apellido_materno?.toLowerCase();

					if (values.detalle.pretencion_renta_formato) {
						//Si se ingresó una pretención de renta.
						values.detalle.pretencion_renta = MonedaToMonto(values.detalle.pretencion_renta_formato);
					}
					if (values.ubicacion.region_object) {
						//Si se seleccionó una región, se asigna.
						values.ubicacion.region = values.ubicacion.region_object.nombre;
					}
					if (values.detalle.licencias_conducir_objects.length > 0) {
						//Si se seleccionaron licencias de conducir, se asignan.
						values.detalle.licencias_conducir = values.detalle.licencias_conducir_objects.map(lc => lc.valor);
					}
					if (values.detalle.nivel_ingles_object) {
						//Si se seleccionó un nivel de inglés, se asigna.
						values.detalle.nivel_ingles = values.detalle.nivel_ingles_object.valor;
					}
					if (values.detalle.is_discapacitado_object) {
						//Si se seleccionó discapacidad, se asigna.
						values.detalle.is_discapacitado = values.detalle.is_discapacitado_object.valor;
					}
					if (values.detalle.relacion_cyd_object) {
						//Si se seleccionó relación con CyD, se asigna.
						values.detalle.relacion_cyd = values.detalle.relacion_cyd_object.valor;
					}
					if (values.detalle.zonas_trabajo_object.length > 0) {
						//Si se seleccionaron zonas de trabajo, se asigna.
						values.detalle.zonas_trabajo = values.detalle.zonas_trabajo_object;
					}
					if (values.detalle.sistema_turno_object) {
						//Si se seleccionó sistema de turnos, se asigna.
						values.detalle.sistema_turno = values.detalle.sistema_turno_object.valor;
					}

					//Se sube el CV a la nube CyD.
					let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
					let uploadedCurriculum = await uploadFileToCloudStorage(values.file, "curriculums", `curriculum_vitae_${ahora}`);
					values.curriculum_vitae = uploadedCurriculum;

					//Se asigna el porcentaje de completitud de datos.
					values.porcentaje = PorcentajeCompletitud(values, values.estudios.length, values.experiencias.length, true);

					//Se agrega nuevo postulante.
					let response = await PostulanteRequest.PostulanteAgregar(values);
					let postulante = response.data;

					//Nombre completo del postulante.
					let nombreComleto = `${postulante.nombre} ${postulante.apellido_paterno} ${postulante.apellido_materno}`;

					// Datos del curriculum.
					let curriculum = {
						_postulante_ref: postulante._id,
						nombre: nombreComleto,
						run_postulante: postulante.run,
						tags: [
							"Repositorio",
							"Contacto",
						],
						curriculum_vitae: uploadedCurriculum,
						origen: "Repositorio",
						_reclutador_ref: "5e0f291c70edaf00137fd4bd",
						_gerencia_ref: "5e0f28b070edaf00137fcf45",
					}
					//Se agrega el nuevo documento para escaneo.
					await CurriculumRequests.Agregar(curriculum);

					//Si se incluyeron estudios.
					if (values.estudios.length > 0) {
						for (const estudio of values.estudios) {
							if (estudio.file) {
								//Se sube el archivo a la nube.
								const archivo = await uploadFileToCloudStorage(estudio.file, "certificados-estudio", `${estudio.file.name}_${ahora}`);
								estudio.certificado = archivo;
								//Se agregan estudio del postulante.
								await PostulanteRequest.EstudioAgregar(postulante._id, estudio);
							}
						}
					}

					//Si se incluyen experiencias.
					if (values.experiencias.length > 0) {
						for (const experiencia of values.experiencias) {
							if (experiencia.empresa_object.is_new) {
								//Si se agregó una empresa nueva.
								let nuevaEmpresa = {
									nombre: experiencia.empresa_object.nombre,
									contacto: {
										sitio_web: "",
									},
								}
								//Se crea la nueva empresa.
								await EmpresaRequests.Agregar(nuevaEmpresa);
							}
							//Se agregan estudio del postulante.
							await PostulanteRequest.ExperienciaAgregar(postulante._id, experiencia);
						}
					}

					//Si se incluyen adjuntos.
					if (values.adjuntos.length > 0) {
						for (const adjunto of values.adjuntos) {
							//Archivo con el nuevo nombre. https://pqina.nl/blog/rename-a-file-with-javascript/
							const renamedFile = new File([adjunto.file], String(adjunto.file.name).replace(/^.*\./, `${adjunto.nombre}.`), { type: adjunto.file.type });
							//Se sube el adjunto a la nube.
							const archivo = await uploadFileToCloudStorage(renamedFile, "adjuntos", `adjunto_${ahora}`);
							adjunto.adjunto = archivo;
							await PostulanteRequest.AdjuntoAgregar(postulante._id, adjunto.adjunto);
						}
					}

					let nombreMayusculas = ToTitleCase(nombreComleto);
					//Template de postulación exitosa.
					let templateHTML = PostulacionExitosa(nombreMayusculas);
					//Se envia email por postulación exitosa.
					await SendHTML(values.contacto.email, "Postulación exitosa", templateHTML);

					notistack.enqueueSnackbar("Postulación subida exitosamente.", {
						variant: "success",
						anchorOrigin: {
							horizontal: "center",
							vertical: "bottom"
						},
						action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
					});
				}
			} catch (error) {
				console.error(error);
				captureException(error);
				notistack.enqueueSnackbar("Error al intentar subir la postulación.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				helper.resetForm();
			}
		},
	});

	/**
	 * Método encargado de verificar si se generó un error y mostrar un mensaje al usuario, si corresponde.
	 * @param {*} error Datos del error.
	 * @param {*} message Mensaje de error.
	 */
	function ErrorSnackbar(error, message) {
		if (error) {
			notistack.enqueueSnackbar(message, {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		}
	}

	useEffect(() => {
		ErrorSnackbar(DiscapacidadesSWR.error, "Error al intentar obtener datos de las discapacidades.");
		ErrorSnackbar(LicenciasConducirSWR.error, "Error al intentar obtener datos de las licencias de conducir.");
		ErrorSnackbar(NivelesInglesSWR.error, "Error al intentar obtener datos de los niveles de Inglés.");
		ErrorSnackbar(InstitucionesSWR.error, "Error al intentar obtener datos de las instituciones.");
		ErrorSnackbar(TitulosSWR.error, "Error al intentar obtener datos de los títulos.");
		ErrorSnackbar(EmpresasSWR.error, "Error al intentar obtener datos de las empresas.");
		ErrorSnackbar(RelacionesSWR.error, "Error al intentar obtener datos de las relaciones con CyD.");
		ErrorSnackbar(NivelesEstudioSWR.error, "Error al intentar obtener datos de los niveles de estudio.");
	}, [notistack, DiscapacidadesSWR.error, LicenciasConducirSWR.error, NivelesInglesSWR.error, NivelesEstudioSWR.error, InstitucionesSWR.error, TitulosSWR.error, RelacionesSWR.error, EmpresasSWR.error]);

	/**
	 * Método encargada de obtener la cantidad de errores del formulario.
	 * Por cada error, se verifica si existe y se aumenta la cantidad.
	 * @returns Cantidad de errores.
	 */
	function CantErrores() {
		let cantErrores = 0;
		cantErrores += formik.errors.run ? 1 : 0;
		cantErrores += formik.errors.nombre ? 1 : 0;
		cantErrores += formik.errors.apellido_paterno ? 1 : 0;
		cantErrores += formik.errors.contacto && formik.errors.contacto.email ? 1 : 0;
		cantErrores += formik.errors.contacto && formik.errors.contacto.telefono ? 1 : 0;
		cantErrores += formik.errors.genero ? 1 : 0;
		cantErrores += formik.errors.file ? 1 : 0;
		return formik.dirty ? cantErrores : 7;
	}

	/**
	 * Método encargado de calcular el porcentaje de completitud de datos.
	 * @returns Porcentaje actual.
	 */
	function PorcentajeProgreso() {
		let cantErrores = CantErrores();
		let completados = 7 - cantErrores;
		return (completados * 100) / 7;
	}

	return (
		<PostulacionContext.Provider
			value={{
				regiones_comunas: RegionesComunas,
				generos: Generos,
				discapacidades: DiscapacidadesSWR,
				licencias_conducir: LicenciasConducirSWR,
				niveles_ingles: NivelesInglesSWR,
				niveles_estudio: NivelesEstudioSWR,
				instituciones: InstitucionesSWR,
				titulos: TitulosSWR,
				relaciones_cyd: RelacionesSWR,
				empresas: EmpresasSWR,
				porcentaje: PorcentajeProgreso(),
				cant_errores: CantErrores(),
				formik: formik,
			}}
		>
			<Page />
		</PostulacionContext.Provider>
	);
}