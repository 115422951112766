import React, { useContext } from "react";
import { captureException } from "@sentry/react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Moment from "moment";
import { Autocomplete, Box, Button, Collapse, createFilterOptions, Grid, IconButton, Paper, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PostulacionContext } from "../index";
import { FormikInitialValues, FormikValidationSchema } from "./experiencia_utils";

const filter = createFilterOptions();

export default function ExperienciaDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { empresas, formik } = useContext(PostulacionContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nueva experiencia del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				values.empresa = values.empresa_object.nombre;

				//Si se seleccionó que aún está trabajando, se asigna.
				if (values.is_trabajando_object) {
					values.is_trabajando = values.is_trabajando_object.valor;
				}

				//Se incluye la nueva experiencia.
				formik.setFieldValue("experiencias", [...formik.values.experiencias, values]);

				notistack.enqueueSnackbar("Experiencia del postulante agregada exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				captureException(error);
				notistack.enqueueSnackbar("Error al intentar agregar la experiencia del postulante.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handleCloseDialog();
				helper.resetForm();
				empresas.mutate();
			}
		}
	});

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCloseDialog = () => {
		set_is_open(false);
		formikDialog.resetForm();
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formikDialog.isSubmitting ||
			!formikDialog.values.empresa_object ||
			!formikDialog.values.cargo ||
			!formikDialog.values.fecha_inicio ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					{/* CAMPO EMPRESA */}
					<Grid item xs={6}>
						<Autocomplete
							value={formikDialog.values.empresa_object}
							options={empresas.data?.data || []}
							getOptionLabel={(option) => option.nombre || option}
							onChange={(event, value) => {
								if (value) {
									if (typeof value === "string") {
										//Si es de tipo texto.
										formikDialog.setFieldValue("empresa_object", { nombre: value, is_new: false });
									}
									if (typeof value === "object") {
										//Si es de tipo objeto.
										formikDialog.setFieldValue("empresa_object", { ...value, is_new: Boolean(value.is_new) });
									}
								} else {
									formikDialog.setFieldValue("empresa_object", null);
								}
							}}
							filterOptions={(options, params) => {
								const filtered = filter(options, params);
								// Suggest the creation of a new value
								if (params.inputValue !== '') {
									filtered.push({
										nombre: params.inputValue,
										is_new: true,
									});
								}
								return filtered;
							}}
							freeSolo
							fullWidth
							size="small"
							noOptionsText="Sin opciones"
							renderInput={(params) => (
								<TextField
									label="Empresa"
									variant="outlined"
									fullWidth
									helperText={formikDialog.errors.empresa_object}
									error={Boolean(formikDialog.errors.empresa_object)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO CLASIFICACIÓN */}
					<Grid item xs={6}>
						<TextField
							name="lugar_trabajo"
							label="Área de trabajo"
							value={formikDialog.values.lugar_trabajo}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.lugar_trabajo}
							error={Boolean(formikDialog.errors.lugar_trabajo)}
						/>
					</Grid>

					{/* CAMPO CARGO */}
					<Grid item xs={6}>
						<TextField
							name="cargo"
							label="Cargo"
							value={formikDialog.values.cargo}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.cargo}
							error={Boolean(formikDialog.errors.cargo)}
						/>
					</Grid>

					{/* CAMPO EXPERIENCIA ESPECÍFICA */}
					<Grid item xs={6}>
						<TextField
							name="experiencia_especifica"
							label="Experiencia Específica"
							value={formikDialog.values.experiencia_especifica}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.experiencia_especifica}
							error={Boolean(formikDialog.errors.experiencia_especifica)}
						/>
					</Grid>

					{/* CAMPO FECHA DE INICIO */}
					<Grid item xs={4}>
						<DatePicker
							label="Inicio"
							value={formikDialog.values.fecha_inicio}
							onChange={date => formikDialog.setFieldValue("fecha_inicio", date)}
							views={["year", "month"]}
							openTo="year"
							minDate={Moment().add(-50, "years").startOf("year")}
							maxDate={Moment().add(1, "years").endOf("year")}
							renderInput={(params) => (
								<TextField
									size="small"
									fullWidth
									style={{ backgroundColor: "white" }}
									helperText={formikDialog.errors.fecha_inicio}
									error={Boolean(formikDialog.errors.fecha_inicio)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO FECHA DE TÉRMINO */}
					<Grid item xs={4}>
						<DatePicker
							label="Término"
							value={formikDialog.values.fecha_termino}
							onChange={date => formikDialog.setFieldValue("fecha_termino", date)}
							views={["year", "month"]}
							openTo="year"
							minDate={Moment().add(-50, "years").startOf("year")}
							maxDate={Moment().add(1, "years").endOf("year")}
							renderInput={(params) => (
								<TextField
									size="small"
									fullWidth
									style={{ backgroundColor: "white" }}
									helperText={formikDialog.errors.fecha_termino}
									error={Boolean(formikDialog.errors.fecha_termino)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO ESTÄ TRABAJANDO ACTUALMENTE */}
					<Grid item xs={4}>
						<Autocomplete
							name="is_trabajando_object"
							options={[{ label: "Si", valor: true }, { label: "No", valor: false }]}
							getOptionLabel={(option) => option.label}
							value={formikDialog.values.is_trabajando_object}
							onChange={(event, value) => formikDialog.setFieldValue("is_trabajando_object", value)}
							size="small"
							fullWidth
							noOptionsText="Sin opciones"
							renderInput={(params) => (
								<TextField
									label="¿Aún trabaja ahí?"
									variant="outlined"
									helperText={formikDialog.errors.is_trabajando_object}
									error={Boolean(formikDialog.errors.is_trabajando_object)}
									{...params}
								/>
							)}
						/>
					</Grid>

					{/* CAMPO COMENTARIO */}
					<Grid item xs={12}>
						<TextField
							name="comentario"
							label="Descripción de funciones"
							value={formikDialog.values.comentario}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							multiline
							size="small"
							helperText={formikDialog.errors.comentario}
							error={Boolean(formikDialog.errors.comentario)}
						/>
					</Grid>

					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box pr={1}>
								<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
									Agregar experiencia
								</Button>
							</Box>
							<Box pl={1}>
								<Button variant="contained" onClick={handleCloseDialog} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}