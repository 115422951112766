import React from "react";
import {
	AppBar,
	Toolbar,
	Typography,
} from "@mui/material";

const LOGO_CYDOCS_COLOR = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";

export default function AppBarComponent(props) {

	return (
		<AppBar position="fixed" color="inherit">
			<Toolbar>
				<img
					src={LOGO_CYDOCS_COLOR}
					alt="CyDocs"
					style={{
						height: "40px",
					}}
				/>
				<Typography style={{ color: "#9e9e9e", paddingLeft: 10 }}>
					Postulaciones CyD
				</Typography>
			</Toolbar>
		</AppBar>
	);
}