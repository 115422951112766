import Axios from "axios";
import { RECLUTAMIENTO } from "../../constants/urls";

/**
 * Método encargado de agregar un postulante.
 * @param {*} postulante Datos del postulante.
 * @returns Datos del nuevo postulante.
 */
export async function PostulanteAgregar(postulante) {
	try {
		let url = `${RECLUTAMIENTO}/postulantes`;
		let response = await Axios.post(url, postulante);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de verificar si un postulante existe, por su RUN.
 * @param {*} postulanteRUN RUN del postulante.
 * @returns TRUE: Postulante ya registrado. FALSE: Postulante no registrado.
 */
export async function VerificarRUN(postulanteRUN) {
	try {
		if (postulanteRUN) {
			let url = `${RECLUTAMIENTO}/postulantes?run_eq=${postulanteRUN}`;
			let response = await Axios.get(url);
			let check = Boolean(response.data && response.data.data && response.data.data.length > 0);
			return check;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un estudio a un postulante.
 * @param {*} postulanteID ID del postulante.
 * @param {*} estudio Datos del estudio.
 * @returns Response.
 */
export async function EstudioAgregar(postulanteID, estudio) {
	try {
		if (postulanteID && estudio) {
			let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/estudios`;
			let response = await Axios.post(url, { _postulante_ref: postulanteID, ...estudio });
			return response.data;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una experiencia a un postulante.
 * @param {*} postulanteID ID del postulante.
 * @param {*} experiencia Datos de la experiencia.
 * @returns Response.
 */
export async function ExperienciaAgregar(postulanteID, experiencia) {
	try {
		if (postulanteID && experiencia) {
			let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/experiencias`;
			let response = await Axios.post(url, { _postulante_ref: postulanteID, ...experiencia });
			return response.data;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un adjunto a un postulante.
 * @param {*} postulanteID ID del postulante.
 * @param {*} adjunto Datos del adjunto.
 * @returns Response.
 */
export async function AdjuntoAgregar(postulanteID, adjunto) {
	try {
		if (postulanteID && adjunto) {
			let url = `${RECLUTAMIENTO}/postulantes/${postulanteID}/adjuntos`;
			let response = await Axios.post(url, { _postulante_ref: postulanteID, adjunto: adjunto });
			return response.data;
		}
	} catch (error) {
		console.error(error);
		throw error;
	}
}