/**
 * Método encargado de obtener el template de email, por postulación exitosa.
 * @param {*} nombrePostulante Nombre del postulante.
 * @returns Template formateado.
 * @link https://material.io/develop/web/getting-started
 */
export function PostulacionExitosa(nombrePostulante) {
	let template = `
		<html>
			<head>
				<style>
					div.root {
						align-items: center;
						justify-content: center;
						margin: auto;
						width: 50%;
						padding: 10px;
					}
					div.rounded {
						border: 2px solid #d4d2d2;
						border-radius: 25px;
						padding: 25px;
					}
					img.center {
						display: block;
						margin-left: auto;
						margin-right: auto;
						width: 100;
					}
				</style>
			</head>
			<body>
				<div class="root">
					<div class="rounded">
						<p style="text-align:center">Hola ${nombrePostulante}, tu información fue recibida exitosamente.</p>

						<p style="text-align:center">Tus datos de postulación serán revisados en caso que exista un requerimiento con tu perfil laboral.</p>
						
						<p style="text-align:center">Agradecemos tu interés por formar parte de nuestra empresa.</p>
						
						<p style="text-align:center">
							<b>POR FAVOR NO RESPONDER ESTE CORREO</b>
						</p>

						<img src="${LOGO_CYD}" alt="Logo CyD" class="center">
						
						<p style="text-align:center">
							<a href="www.cydingenieria.cl">cydingenieria.cl</a>
						</p>
					</div>
				</div>
			</body>
		</html>
	`;
	return template;
}

const LOGO_CYD = "https://cydingenieria.cl/wp-content/uploads/2019/12/logo-color.png";