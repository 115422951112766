import Axios from "axios";
import { RECLUTAMIENTO } from "../../constants/urls";

/**
 * Método encargado de agregar un nuevo registro de curriculum.
 * @param {*} curriculum Datos del curriculum.
 * @returns Response.
 */
export async function Agregar(curriculum) {
	try {
		let url = `${RECLUTAMIENTO}/curriculums`;
		let response = await Axios.post(url, curriculum);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}