import React from "react";
import AppBar from "./components/appBar";
import Formulario from "./containers/formulario";

function App() {
	return (
		<div style={{ backgroundColor: "#F3F3F3" }}>
			<AppBar />
			<Formulario />
		</div>
	);
}

export default App;
