import Axios from "axios";
import { STORAGE } from "../constants/urls";

/**
 * Método encargado de subir una foto a Cloud Storage.
 * @param {*} file Archivo seleccionado.
 * @param {*} route Ruta de carpeta donde se subirá.
 * @param {*} name Nombre del archivo.
 * @returns URL de la imagen subida.
 */
export async function uploadFileToCloudStorage(file, route, name) {
	try {
		var formData = new FormData();
		formData.append("folder", route);
		formData.append("bucket", "reclutamiento-cyd");
		formData.append("name", name);
		formData.append("file", file);

		let upload = await Axios.post(`${STORAGE}/upload`, formData);
		if (!Boolean(upload.status === 201 || upload.status === 200)) {
			throw new Error("Error al intentar subir el archivo.");
		}
		let uploadedFile = {
			mime_type: upload.data.content_type,
			nombre: name,
			nombre_original: file.name,
			repositorio: `${upload.data.bucket}/${route}`,
			url: upload.data.pathname,
		}
		return uploadedFile;
	} catch (error) {
		console.error(error);
		throw error;
	}
}