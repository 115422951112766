import React, { useContext } from "react";
import { captureException } from "@sentry/react";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { Box, Button, Collapse, Grid, IconButton, Paper, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import { PostulacionContext } from "../index";
import { FormikInitialValues, FormikValidationSchema } from "./adjunto_utils";
import FileInput from "../../../components/fileInput";

export default function AdjuntoDialog(props) {
	const {
		is_open,
		set_is_open,
	} = props;

	const { formik } = useContext(PostulacionContext);
	const notistack = useSnackbar();

	const formikDialog = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async function (values, helper) {
			try {
				notistack.enqueueSnackbar("Agregando nuevo adjunto del postulante...", {
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
				});

				//Se actualizan los datos del postulante.
				formik.setFieldValue("adjuntos", [...formik.values.adjuntos, values]);

				notistack.enqueueSnackbar("Adjunto del postulante agregado exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} catch (error) {
				console.error(error);
				captureException(error);
				notistack.enqueueSnackbar("Error al intentar agregar el adjunto del postulante.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			} finally {
				handleCloseDialog();
				helper.resetForm();
			}
		}
	});

	/**
	 * Método encargado de cerrar el popup.
	 */
	const handleCloseDialog = () => {
		set_is_open(false);
		formikDialog.resetForm();
	}

	/**
	 * Método encargado de determinar si el botón está habilitado o deshabilitado.
	 * @returns Estado del botón.
	 */
	const isDisabled = () => {
		let check = Boolean(
			formikDialog.isSubmitting ||
			!formikDialog.values.nombre ||
			Object.keys(formikDialog.errors).length > 0
		);
		return check;
	}

	return (
		<Collapse in={is_open}>
			<Paper style={{ padding: 15, borderRadius: 25 }}>
				<Grid container spacing={2}>
					{/* NOMBRE DEL ADJUNTO */}
					<Grid item xs={12}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formikDialog.values.nombre}
							onChange={formikDialog.handleChange}
							variant="outlined"
							fullWidth
							size="small"
							helperText={formikDialog.errors.nombre}
							error={Boolean(formikDialog.errors.nombre)}
						/>
					</Grid>

					{/* CAMPO ADJUNTO */}
					<Grid item xs={12}>
						<FileInput
							inputName="file"
							label="adjunto"
							handleOnChange={(e) => formikDialog.setFieldValue("file", e.target.files[0])}
							meta={{ touched: formikDialog.touched.file, error: formikDialog.errors.file }}
							accept="image/png, image/jpg, image/jpeg, application/pdf"
							buttonColor="primary"
							buttonVariant="outlined"
							value={formikDialog.values.file}
							textVariant="outlined"
							size="small"
						/>
					</Grid>

					{/* BOTONES AGREGAR/CANCELAR */}
					<Grid item xs={12}>
						<Box display="flex" justifyContent="flex-end">
							<Box pr={1}>
								<Button variant="contained" onClick={formikDialog.submitForm} disabled={isDisabled()} color="primary">
									Agregar adjunto
								</Button>
							</Box>
							<Box pl={1}>
								<Button variant="contained" onClick={handleCloseDialog} disabled={formikDialog.isSubmitting} color="secondary">
									Cerrar
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Paper>
		</Collapse>
	);
}