import React, { useContext } from "react";
import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { PostulacionContext } from "../index";

export default function PersonalDetalle(props) {
	const {
		licencias_conducir,
		niveles_ingles,
		relaciones_cyd,
		formik,
	} = useContext(PostulacionContext);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h6" style={{ marginLeft: 10 }}>Cuéntanos más de ti</Typography>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="detalle.nivel_ingles_object"
					options={niveles_ingles.data?.data || []}
					getOptionLabel={(nivelIngles) => nivelIngles.valor}
					value={formik.values.detalle.nivel_ingles_object}
					onChange={(event, value) => formik.setFieldValue("detalle.nivel_ingles_object", value)}
					loading={niveles_ingles.isValidating}
					size="small"
					fullWidth
					renderInput={(params) => (
						<TextField
							label="Nivel Inglés"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.detalle && formik.errors.detalle.nivel_ingles_object}
							error={Boolean(formik.errors.detalle && formik.errors.detalle.nivel_ingles_object)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="detalle.licencias_conducir_objects"
					options={licencias_conducir.data?.data || []}
					getOptionLabel={(licenciaConducir) => licenciaConducir.valor}
					value={formik.values.detalle.licencias_conducir_objects}
					onChange={(event, value) => formik.setFieldValue("detalle.licencias_conducir_objects", value)}
					loading={licencias_conducir.isValidating}
					multiple
					size="small"
					fullWidth
					renderInput={(params) => (
						<TextField
							label="Licencias de Conducir"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.detalle && formik.errors.detalle.licencias_conducir_objects}
							error={Boolean(formik.errors.detalle && formik.errors.detalle.licencias_conducir_objects)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="detalle.is_discapacitado_object"
					options={[{ label: "Si", valor: true }, { label: "No", valor: false }]}
					getOptionLabel={(option) => option.label}
					value={formik.values.detalle.is_discapacitado_object}
					onChange={(event, value) => formik.setFieldValue("detalle.is_discapacitado_object", value)}
					size="small"
					fullWidth
					noOptionsText="Sin opciones"
					renderInput={(params) => (
						<TextField
							label="Discapacidad"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.detalle && formik.errors.detalle.is_discapacitado_object}
							error={Boolean(formik.errors.detalle && formik.errors.detalle.is_discapacitado_object)}
							{...params}
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6}>
				<Autocomplete
					name="detalle.relacion_cyd_object"
					options={relaciones_cyd.data?.data || []}
					getOptionLabel={(relacion) => relacion.valor}
					value={formik.values.detalle.relacion_cyd_object}
					onChange={(event, value) => formik.setFieldValue("detalle.relacion_cyd_object", value)}
					size="small"
					fullWidth
					noOptionsText="Sin opciones"
					renderInput={(params) => (
						<TextField
							label="Relación con CyD"
							variant="outlined"
							style={{ backgroundColor: "white" }}
							helperText={formik.errors.detalle && formik.errors.detalle.relacion_cyd_object}
							error={Boolean(formik.errors.detalle && formik.errors.detalle.relacion_cyd_object)}
							{...params}
						/>
					)}
				/>
			</Grid>
		</Grid>
	);
}