import { mixed, object, string, date, array } from "yup";

const REGEX_RUN = /^(\d{7}|\d{8})-[0-9kK]$/;
const REGEX_TEXTO = /^[(a-z)(áéíóú)(äëïöü)(A-Z)(ÁÉÍÓÚ)(ÄËÏÖÜ)(ñÑ)( )]+$/;
const REGEX_TELEFONO = /^((\+56)?(9))?\d{8}$/;

export const FormikInitialValues = {
	run: "",
	nombre: "",
	apellido_paterno: "",
	apellido_materno: "",
	fecha_nacimiento: null,
	genero: "",
	file: null,
	curriculum_vitae: null,
	origen: "Trabaja con Nosotros",
	contacto: {
		email: "",
		telefono: "",
	},
	ubicacion: {
		direccion: "",
		ciudad: "",
		comuna: "",
		region: "",
		region_object: null,
	},
	detalle: {
		licencias_conducir: [],
		licencias_conducir_objects: [],
		nivel_ingles: "",
		nivel_ingles_object: null,
		is_discapacitado: false,
		is_discapacitado_object: null,
		relacion_cyd: "",
		relacion_cyd_object: null,
		zonas_trabajo: [],
		zonas_trabajo_object: [],
		sistema_turno: false,
		sistema_turno_object: null,
		pretencion_renta_formato: "",
		pretencion_renta: 0,
		tags: [
			"Repositorio",
			"Contacto",
			"Trabaja con Nosotros",
		],
		_reclutador_ref: "5e0f291c70edaf00137fd4bd",
	},
	estudios: [],
	experiencias: [],
	adjuntos: [],
}

export const FormikValidationSchema = object().shape({
	run: string()
		.nullable()
		.min(9, ({ min }) => `El RUN debe tener al menos ${min} caracteres.`)
		.max(10, ({ max }) => `El RUN debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_RUN, { message: "El RUN debe tener el formato \"12345678-9\"." })
		.required("El RUN es requerido."),
	nombre: string()
		.nullable()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(50, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_TEXTO, "Solo se permiten letras.")
		.required("El nombre es requerido."),
	apellido_paterno: string()
		.nullable()
		.min(3, ({ min }) => `El primer apellido debe tener al menos ${min} caracteres.`)
		.max(50, ({ max }) => `El primer apellido debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_TEXTO, "Solo se permiten letras.")
		.required("El primer apellido es requerido."),
	apellido_materno: string()
		.nullable()
		.min(3, ({ min }) => `El segundo apellido debe tener al menos ${min} caracteres.`)
		.max(50, ({ max }) => `El segundo apellido debe tener a lo más ${max} caracteres.`)
		.matches(REGEX_TEXTO, "Solo se permiten letras.")
		.optional(),
	fecha_nacimiento: date()
		.max(new Date(), "La fecha de nacimiento no puede ser superior a la fecha actual.")
		.nullable()
		.optional(),
	genero: string()
		.nullable()
		.required("El género es requerido."),
	file: mixed()
		.required("El CV es requerido"),
	contacto: object()
		.shape({
			email: string()
				.email("El correo debe tener el formato \"usuario@dominio\".")
				.required("El correo es requerido."),
			telefono: string()
				.matches(REGEX_TELEFONO, { message: "El teléfono debe tener el formato \"[+56][9]87654321\"." })
				.required("El teléfono es requerido."),
		})
		.optional(),
	ubicacion: object()
		.shape({
			direccion: string()
				.min(2, ({ min }) => `La dirección debe tener al menos ${min} caracteres.`)
				.max(2000, ({ max }) => `La dirección debe tener a lo más ${max} caracteres.`)
				.optional(),
			ciudad: string()
				.min(2, ({ min }) => `La ciudad debe tener al menos ${min} caracteres.`)
				.max(2000, ({ max }) => `La ciudad debe tener a lo más ${max} caracteres.`)
				.matches(REGEX_TEXTO, "Solo se permiten letras.")
				.optional(),
			comuna: string()
				.nullable()
				.optional(),
			region_object: object()
				.nullable()
				.optional(),
		})
		.optional(),
	detalle: object()
		.shape({
			licencias_conducir_objects: array()
				.of(object())
				.optional(),
			nivel_ingles_object: object()
				.nullable()
				.optional(),
			is_discapacitado_object: object()
				.nullable()
				.optional(),
			relacion_cyd_object: object()
				.nullable()
				.optional(),
			zonas_trabajo_object: object()
				.nullable()
				.optional(),
			sistema_turno_object: object()
				.nullable()
				.optional(),
			pretencion_renta_formato: string()
				.nullable()
				.optional(),
		})
		.optional(),
	estudios: array()
		.of(object())
		.optional(),
	experiencias: array()
		.of(object())
		.optional(),
	adjuntos: array()
		.of(object())
		.optional(),
});