import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/react";
import "moment/locale/es";

Sentry.init({
	dsn: "https://a9d6d6cb5757ead2bfee6c25647510e5@o4504243009093632.ingest.sentry.io/4506003522781184",
	enabled: true,
});

const theme = createTheme({
	typography: {
		fontSize: 14,
		htmlFontSize: 17,
		fontFamily: "Poppins",
	},
	palette: {
		primary: {
			light: "#a64c46",
			main: "#731f1f",
			dark: "#430000",
			contrastText: "#ffffff",
		},
		secondary: {
			light: "#4f5b62",
			main: "#263238",
			dark: "#000a12",
			contrastText: "#ffffff",
		},
		text: {
			primary: "rgba(0, 0, 0, 0.87)",
			secondary: "rgba(0, 0, 0, 0.54)",
		},
	},
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<SnackbarProvider>
				<LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
					<App />
				</LocalizationProvider>
			</SnackbarProvider>
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
