import { object, string, boolean } from "yup";

export const POSTGRADO = "Postgrado";

export const FormikInitialValues = {
	institucion_object: null,
	institucion: "",
	nivel_estudio_object: null,
	nivel_estudio: "",
	tipo: "",
	titulo_object: null,
	titulo: "",
	fecha_inicio: null,
	fecha_termino: null,
	certificado: null,
	file: null,
	is_verificado: false,
}

export const FormikValidationSchema = object().shape({
	institucion_object: object()
		.nullable()
		.required("La institucion es requerida."),
	institucion: string()
		.optional(),
	nivel_estudio_object: object()
		.nullable()
		.required("El nivel de estudio es requerido."),
	nivel_estudio: string()
		.optional(),
	tipo: string()
		.min(3, ({ min }) => `El tipo debe tener al menos ${min} caracteres.`)
		.max(200, ({ max }) => `El tipo debe tener a lo más ${max} caracteres.`)
		.optional(),
	titulo_object: object()
		.nullable()
		.required("El titulo es requerido."),
	titulo: string()
		.optional(),
	fecha_inicio: object()
		.nullable()
		.optional(),
	fecha_termino: object()
		.nullable()
		.optional(),
	certificado: object()
		.nullable()
		.optional(),
	file: object()
		.nullable()
		.optional(),
	is_verificado: boolean()
		.optional()
});